import React from "react";
import { compose } from "recompose";
import cx from "classnames";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { SpecificTheme } from "../../theme/default";

export type TypoProps = {
  type: "serviceHeader" | "serviceSubheader";
} & Omit<TypographyProps, "classes">;

type ComponentProps = TypoProps & WithStyles<typeof styles>;

const styles = (theme: SpecificTheme) =>
  createStyles({
    serviceHeader: {
      fontSize: "2rem",
      fontWeight: 400,
      color: theme.palette.primary.main
    },
    serviceSubheader: {
      marginTop: "2rem",
      marginBottom: "1rem",
      fontSize: "1.15rem",
      fontWeight: "bold",
      color: theme.palette.primary.main
    },
    inlineHeader: {
      marginTop: "0.5rem",
      marginBottom: 0,
      color: theme.palette.primary.main
    }
  });

const Typo: React.FC<ComponentProps> = ({
  type,
  children,
  classes,
  className,
  ...typoProps
}) => {
  switch (type) {
    // Special cases
    case "serviceHeader": {
      const classnames = cx(classes.serviceHeader, className);
      return (
        <Typography variant="h1" {...typoProps} className={classnames}>
          {children}
        </Typography>
      );
    }
    case "serviceSubheader": {
      const classnames = cx(classes.serviceSubheader, className);
      return (
        <Typography variant="h3" {...typoProps} className={classnames}>
          {children}
        </Typography>
      );
    }
  }
};

export default compose<ComponentProps, TypoProps>(withStyles(styles))(Typo);
