import React from "react";
import classnames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { SpecificTheme } from "../../theme/default";
import { get } from "../../helpers/data";
import { ServiceBackgroundImage } from "./__generated__/ServiceBackgroundImage";
import { compose } from "recompose";

type PublicProps = {
  className?: string;
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    image: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.specific.backgroundFront,
      backgroundPosition: "top left",
      backgroundRepeat: "repeat-y",
      backgroundSize: "cover"
    }
  });

type ComponentProps = PublicProps & WithStyles<typeof styles>;

const BackgroundService: React.FC<ComponentProps> = ({
  classes,
  className,
  children
}) => {
  const data = useStaticQuery<ServiceBackgroundImage>(
    graphql`
      query ServiceBackgroundImage {
        background: contentfulImages(name: { eq: "servicesbackground" }) {
          id
          name
          image {
            title
            description
            fluid(maxWidth: 2500, quality: 80) {
              ...ContentfulFluidImage
            }
          }
        }
      }
    `
  );

  const image = get(data, "background", "image", "fluid");
  const imageClasses = classnames(classes.image, className);

  if (image) {
    // Retrieve corresponding image

    return (
      <BackgroundImage
        Tag="section"
        className={imageClasses}
        fluid={image}
        // backgroundColor={`#040e18`}
      >
        {children}
      </BackgroundImage>
    );
  }

  return null;
};

export default compose<ComponentProps, PublicProps>(withStyles(styles))(
  BackgroundService
);
