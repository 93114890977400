import React from "react";
import { compose } from "recompose";
import {
  Button,
  Grid,
  WithStyles,
  withStyles,
  createStyles
} from "@material-ui/core";
import Typo from "../general/Typo";
import { ReferenzenPageQuery_allContentfulReference_edges_node } from "../../pages/__generated__/ReferenzenPageQuery";
import { SpecificTheme } from "../../theme/default";
import { Image } from "../image/Image";

type ReferenceProps = {
  reference: ReferenzenPageQuery_allContentfulReference_edges_node;
};

type InnerProps = ReferenceProps & WithStyles<typeof styles>;

const Reference: React.FC<InnerProps> = ({ reference, classes }) => {
  return (
    <Grid
      container
      alignContent="stretch"
      className={classes.referenceContainer}
    >
      <Grid item>
        <Image image={reference.image} className={classes.referenceImage} />
      </Grid>
      <Grid item className={classes.referenceContent}>
        <Typo type="serviceSubheader" className={classes.referenceSubTitle}>
          {reference.subheader}
        </Typo>
        <Typo type="serviceHeader" className={classes.referenceTitle}>
          {reference.header}
        </Typo>
        <div
          className={classes.referenceDescription}
          dangerouslySetInnerHTML={{
            __html: reference.intro.childMarkdownRemark.html
          }}
        />
        {reference.link && (
          <a
            href={reference.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.referenceLink}
            >
              Ansehen
            </Button>
          </a>
        )}
      </Grid>
    </Grid>
  );
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    referenceContainer: {
      backgroundColor: "#fff",
      marginTop: "1rem",
      marginBottom: "1rem",
      minHeight: "360px",
      [theme.breakpoints.down("sm")]: {
        width: "93vw",
        height: "100%",
        margin: "1rem auto"
      }
    },
    referenceContent: {
      minHeight: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: "0 10px 10px 10px"
      },
      [theme.breakpoints.up("md")]: {
        padding: "0 2vw 1vw 0"
      }
    },
    referenceImage: {
      height: "360px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        width: "91vw"
      },
      [theme.breakpoints.up("md")]: {
        width: "28vw"
      },
      [theme.breakpoints.only("xl")]: {
        width: "20vw"
      }
    },
    referenceTitle: {
      [theme.breakpoints.up("md")]: {
        width: "60vw",
        paddingLeft: "30px"
      },
      [theme.breakpoints.up("xl")]: {
        width: "50vw"
      }
    },
    referenceSubTitle: {
      [theme.breakpoints.up("md")]: {
        width: "60vw",
        paddingLeft: "30px"
      },
      [theme.breakpoints.up("xl")]: {
        width: "50vw"
      }
    },
    referenceDescription: {
      [theme.breakpoints.up("md")]: {
        width: "60vw",
        paddingLeft: "30px"
      },
      [theme.breakpoints.up("xl")]: {
        width: "50vw"
      }
      // color: "#fff"
    },
    referenceLink: {
      [theme.breakpoints.up("md")]: {
        marginLeft: "30px"
      },
      textDecoration: "none"
    }
  });

export default compose<InnerProps, ReferenceProps>(withStyles(styles))(
  Reference
);
